import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/browser';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Store } from '@ngxs/store';
import { AuthState } from '@medsurf/state';

// Sentry Init
Sentry.init({
  dsn: environment.config.sentry.dsn,
  environment: environment.config.sentry.environment,
  autoSessionTracking: false,
  tracesSampleRate: 1,
  release: environment.config.version
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then((ref) => {
    const store = ref.injector.get(Store);
    store.select(AuthState.user).subscribe((user) => {
      if (user) {
        Sentry.setUser({
          id: user.id.toString(),
          username: `${user.firstName} ${user.lastName}`,
        });
        Sentry.setContext('user', user);
      }
    });
  })
  .catch(err => console.error(err));
